#product_navbar {
	border-bottom: 1px solid var(--primary);
}

#product_navbar_logo {
	height: 3.5em;
}

#user_context_bar {
	width: fit-content;
	padding: 0.3em;
	float: right;
	margin-right: 1em;
	border: 1px solid var(--primary);
	border-top: 0;
}

.user_context_link {
	background: var(--primary);
	padding: 2px;
}

.user_context_link:hover span {
	text-decoration: underline;
}

.product_layout .content {
	--resp-side-margin: 1%;
	position: relative;
	width: auto;
	margin-left: var(--resp-side-margin);
	margin-right: var(--resp-side-margin);
	margin-top: 1em;
	margin-bottom: 2em;
}

@media (min-width: 992px) {
	.product_layout .content {
		--resp-side-margin: 5%;
	}
}
