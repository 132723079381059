@charset "UTF-8";

:root {
	--primary: #ff7800;
	--secondary: #345995;
}

@font-face {
	font-family: "Crimson Pro";
	src: url("./fonts/CrimsonPro-VariableFont_wght.ttf");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Crimson Pro";
	src: url("./fonts/CrimsonPro-Italic-VariableFont_wght.ttf");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Grenze Gotisch";
	src: url("./fonts/GrenzeGotisch-VariableFont_wght.ttf");
	font-weight: normal;
	font-style: normal;
}

body {
	font-size: 1.1em;
	text-align: justify;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*,
body {
	font-family: "Crimson Pro", serif, "Apple Color Emoji";
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.header-font,
form fieldset legend {
	font-family: "Grenze Gotisch", "Crimson Pro", serif, "Apple Color Emoji";
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.full {
	width: 100%;
	height: 100%;
}

a:hover .link_partly_underlined {
	text-decoration: underline;
}

.height_fit_content {
	height: fit-content;
}

.width_fit_content {
	width: fit-content;
}

.btn-primary {
	color: white;
}
